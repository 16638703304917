/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, ScrollTo) => {
        
        $('#anchor-container a').click(function (event) {
			event.preventDefault();
            $('#anchor-container a').removeClass('is-active')
            $(this).addClass('is-active')
            var anchor = $(this).attr('href')
            gsap.to(window, {duration: 1.2, scrollTo:anchor})
		});

    }
}
