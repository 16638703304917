/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        $('.gchoice').click(function (event) {
            $('.gchoice').removeClass('is-active')
            $(this).addClass('is-active')
		});
    }
}
