/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap, ScrollTrigger) => {


    /*
    |
    | Constants
    |-----------
    */
    const
        $window = $(window),
        $body = $('body'),

        $header = $('header'),
        $burger = $('.burger')
        ;

		$('body').on('loaderEnd', () => console.log('ended'))

		$('header .menu-item-has-children').click(function (event) {
			event.preventDefault();
		});

		$('header .menu-item-has-children').on({
			mouseenter: function () {
				var item_id = $(this).data("id")
				$('.header-sub').addClass('active')

				if (!$(this).hasClass('active')) {
					$('.menu-item-has-children').removeClass('active')
					$('.header-sub .item-container').removeClass('active')

					$(this).toggleClass('active')

					$('.header-sub .item-container').each(function () {
						var el = $(this)

						if (el.data("id") == item_id) {
							el.addClass('active')
						}
					})
				}
			}
		})

		$('.header-sub').on({
			mouseleave: function () {
				$('.menu-item-has-children').removeClass('active')
				$('.header-sub .item-container').removeClass('active')
				$('.header-sub').removeClass('active')
			}
		})

		$('.menu-item:not(.menu-item-has-children)').on({
			mouseenter: function () {
				$('.menu-item-has-children').removeClass('active')
				$('.header-sub .item-container').removeClass('active')
				$('.header-sub').removeClass('active')
			}
		})

		
		/*
        |
        | Header on scroll
        |-----------------
        */
        const hideHeader = gsap.from($header, {
            yPercent: -100,
            paused: true,
            duration: 0.2
        }).progress(1)

        ScrollTrigger.matchMedia({
            "(min-width: 48rem)": function () {

                ScrollTrigger.create({
                    start: 'top -80',
                    end: 99999,
                    onUpdate: (self) => {
                        self.direction === -1 ? hideHeader.play() : hideHeader.reverse()
                    },
                    toggleClass: { className: 'is-scrolled', targets: $header }
                });
            },
            "(max-width: 47rem)": function () {
                hideHeader.play()
            }
        })


		 /*
        |
        | Burger Menu
        |------------
        */
        $burger.on('click', function () {
            $body.toggleClass('is-menu')
        })

        function checkDevice() {
            if ("matchMedia" in window) {
                if (window.matchMedia("(min-width:48rem)").matches) {
                    $body.removeClass('is-menu')
                }
            }
        }
        window.addEventListener('resize', checkDevice, false);


		/*
		|
		| Mouse Follow
		|-----------------
		*/
		var $cursor = $('#cursor-container')

		function moveCursor(e) {
			gsap.to($cursor, {
				left: e.pageX,
				top: e.pageY,
				ease: 'Power4.easeOut',
				duration: 1
			})
		}
		$(window).on('mousemove', moveCursor)
	}
}
