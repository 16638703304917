/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, Swiper) => {
        /*
        |
        | Constants
        |------------
        */

        const sections = document.querySelectorAll('.drop-container')
        const texts = document.querySelectorAll('.hidden-content')

        gsap.set(texts, {
            height: 0
        })

        sections.forEach((section, index) => {
            section.addEventListener('mouseenter', () => {
                const text = texts[index]
                
                texts.forEach((el) => {
                    if (el != text && el.classList.contains('is-active')) {
                        $('.drop-container').removeClass('is-active')
                        el.classList.remove('is-active')
                        gsap.to(el, 0.5, {
                            height: 0,
                            ease: 'Expo.easeOut'
                        })
                    }
                })

                if (text.classList.contains('is-active')) {
                    $('.drop-container').removeClass('is-active')
                    text.classList.remove('is-active')
                    gsap.to(text, 0.5, {
                        height: 0,
                        ease: 'Expo.easeOut'
                    })
                } else {
                    section.classList.add('is-active')
                    text.classList.add('is-active')
                    gsap.set(text, {
                        height: 'auto'
                    })
                    gsap.from(text, 0.5, {
                        height: 0,
                        ease: 'Expo.easeOut'
                    })
                }
            })
        })


        /*
        |
        | Swiper
        |-------------------
        |
        */
        var swiper = new Swiper(".sector-swiper", {
            slidesPerView: 1,
            spaceBetween: 20,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
            breakpoints: {
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              },
        });
    }
}
