/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/js/swiper.min';
import gsap from "gsap";
import CustomEase from "@lib/gsap-pro/CustomEase";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import MaterializeForm from '@components/materialize-form.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import home from '@pages/home.js';
import recrutements from '@pages/recrutements.js';
import news from '@pages/news.js';
import single from '@pages/single.js';
import animations from '@pages/animations.js';
import sample from '@pages/sample.js';
import contact from '@pages/contact.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    'file': animations,
    'dependencies': [app, gsap, CustomEase]
  },
  {
    'file': main,
    'dependencies': [app, gsap, ScrollTrigger]
  },
  {
    'file': home,
    'dependencies': [app, gsap, Swiper],
    'resolve': '#page-home'
  },
  {
    'file': recrutements,
    'dependencies': [app, gsap, ScrollTo],
    'resolve': '#template-recrutements'
  },
  {
    'file': news,
    'dependencies': [app, gsap],
    'resolve': '#page-news-archive'
  },
  {
    'file': single,
    'dependencies': [app],
    'resolve': '#page-news-single'
  },
  {
    'file': sample,
    'dependencies': [app, gsap],
    'resolve': '#page-sample'
  },
  {
    'file': contact,
    'dependencies': [app, MaterializeForm],
    'resolve': '#page-contact'
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

