/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap) => {
		/*
		|
		| Constants
		|------------
		*/
		const sections = document.querySelectorAll('.drop-container')
		const texts = document.querySelectorAll('.hidden-content')

		gsap.set(texts, {
			height: 0
		})

		sections.forEach((section, index) => {
			section.addEventListener('mouseenter', () => {
				const text = texts[index]

				texts.forEach((el) => {
					if (el != text && el.classList.contains('is-active')) {
						el.classList.remove('is-active')
						gsap.to(el, 0.5, {
							height: 0,
							ease: 'Expo.easeOut'
						})
					}
				})

				if (text.classList.contains('is-active')) {
					text.classList.remove('is-active')
					gsap.to(text, 0.5, {
						height: 0,
						ease: 'Expo.easeOut'
					})
				} else {
					text.classList.add('is-active')
					gsap.set(text, {
						height: 'auto'
					})
					gsap.from(text, 0.5, {
						height: 0,
						ease: 'Expo.easeOut'
					})
				}
			})
		})
	}
}
